import axios from "axios";
const MODULE = "(api.js)";

const BASE_URL = `https://prod-api.goreckit.com`;

async function verifyClient(clientId, redirectUri, scopes) {
  let response = null;
  try {
    let data = {
      client_id: clientId,
      redirect_uri: redirectUri,
      scopes,
    };

    let config = {
      method: "post",
      url: `${BASE_URL}/oauth/verify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    response = await axios(config);
    return response.data;
  } catch (e) {
    console.error(`${MODULE} failed to verify: ${e.response.data.message}`);
    return e.response.data;
  }
}

async function sendCode(phoneNumber) {
  let response = null;
  try {
    let data = {
      phone_number: phoneNumber,
    };

    let config = {
      method: "post",
      url: `${BASE_URL}/users/otp`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    response = await axios(config);
    return response.data;
  } catch (e) {
    console.error(`${MODULE} failed to send code: ${e.response.data.message}`);
    return e.response.data;
  }
}

async function verifyCode(phoneNumber, code) {
  let response = null;
  try {
    let data = {
      phone_number: phoneNumber,
      otp: code,
    };

    let config = {
      method: "post",
      url: `${BASE_URL}/users/otp/verify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    response = await axios(config);
    return response.data;
  } catch (e) {
    console.error(
      `${MODULE} failed to verify code: ${e.response.data.message}`
    );
    return e.response.data;
  }
}

async function login(username, password) {
  let response = null;
  try {
    let data = {
      username,
      password,
    };

    let config = {
      method: "post",
      url: `${BASE_URL}/users/signin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    response = await axios(config);
    return response.data;
  } catch (e) {
    console.error(
      `${MODULE} failed to verify code: ${e.response.data.message}`
    );
    return e.response.data;
  }
}

async function googleSigninCallback(idToken) {
  let response = null;
  try {
    let data = {
      id_token: idToken,
    };

    let config = {
      method: "post",
      url: `${BASE_URL}/services/google/callback`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    response = await axios(config);
    return response.data;
  } catch (e) {
    console.error(
      `${MODULE} failed to verify code: ${e.response.data.message}`
    );
    return e.response.data;
  }
}

async function appleSigninCallback(idToken) {
  let response = null;
  try {
    let data = {
      id_token: idToken,
    };

    let config = {
      method: "post",
      url: `${BASE_URL}/services/apple/callback`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    response = await axios(config);
    return response.data;
  } catch (e) {
    console.error(
      `${MODULE} failed to verify code: ${e.response.data.message}`
    );
    return e.response.data;
  }
}

async function linkUser(clientId, scopes, token) {
  let response = null;
  try {
    let data = {
      client_id: clientId,
      scopes,
    };

    let config = {
      method: "post",
      url: `${BASE_URL}/oauth/link`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };
    response = await axios(config);
    return response.data;
  } catch (e) {
    console.error(
      `${MODULE} failed to verify code: ${e.response.data.message}`
    );
    return e.response.data;
  }
}

export {
  verifyClient,
  sendCode,
  verifyCode,
  login,
  googleSigninCallback,
  appleSigninCallback,
  linkUser,
};
