import { useState, useEffect } from "react";
import {
  verifyClient,
  login,
  sendCode,
  verifyCode,
  linkUser,
  googleSigninCallback,
  appleSigninCallback,
} from "./api.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import AppleSignin from "react-apple-signin-auth";

function App() {
  const [clientId, setClientId] = useState();
  const [redirectURI, setRedirectURI] = useState();
  const [scopes, setScopes] = useState();
  const [status, setStatus] = useState("initializing");
  const [errorMessage, setErrorMessage] = useState("failed to verify");

  const [loginType, setLoginType] = useState("code");

  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberValid, setPhoneNumberValid] = useState(null);
  const [code, setCode] = useState("000000");
  const [codeValid, setCodeValid] = useState(null);

  const [username, setUsername] = useState();
  const [usernameValid, setUsernameValid] = useState(null);
  const [password, setPassword] = useState();
  const [passwordValid, setPasswordValid] = useState(null);

  const [token, setToken] = useState(null);

  const [externalAccountId, setExternalAccountId] = useState();

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let _clientId = params.get("client_id");
    let _redirectURI = params.get("redirect_uri");
    let _scopes = params.get("scopes").split(",");

    if (_clientId == null || _redirectURI == null || _scopes == null) {
      setStatus("errorred");
      setErrorMessage("invalid authorization url");
    } else {
      verifyClient(_clientId, _redirectURI, _scopes)
        .then((response) => {
          if (response.success) {
            setStatus("client_verified");
            setClientId(_clientId);
            setRedirectURI(_redirectURI);
            setScopes(_scopes);
          } else {
            setStatus("errorred");
            setErrorMessage(response.message);
          }
        })
        .catch((err) => {
          setStatus("errorred");
          setErrorMessage(`failed: ${err.message}`);

          console.log("status: ", status, "error:", errorMessage);
        });
    }

    return () => {};
  }, []);

  // function useGoogleSignInScript() {
  //   useEffect(() => {
  //     const script = document.createElement("script");
  //     script.src = "https://accounts.google.com/gsi/client";
  //     script.async = true;
  //     script.defer = true;
  //     document.body.appendChild(script);
  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }, []);
  // }

  // useGoogleSignInScript();
  function onPhoneNumberChange(phone) {
    let enteredPhoneNumber = `+${phone}`;
    let phoneNumberRegex = /^\+[0-9]{10,16}$/;
    if (phoneNumberRegex.test(enteredPhoneNumber)) {
      setPhoneNumberValid(true);
      setPhoneNumber(enteredPhoneNumber);
    } else {
      setPhoneNumberValid(false);
    }
  }

  function onCodeLoginSelected() {
    setLoginType("code");
  }

  function onPasswordLoginSelected() {
    setLoginType("password");
  }

  function onSendCodeClick(event) {
    sendCode(phoneNumber)
      .then((response) => {
        if (response.success) {
          setStatus("sent_otp");
        } else {
          setStatus("errorred");
          setErrorMessage("failed to send otp");
          redirect(false, { reason: "invalid_client_configuration" }, 20000);
        }
      })
      .catch((err) => {
        setStatus("errorred");
        setErrorMessage(`failed: ${err.message}`);
      });
  }

  function onCodeChange(event) {
    let codeEntered = event.target.value;
    let codeRegex = /^[0-9]{6}$/;
    if (codeRegex.test(codeEntered)) {
      setCodeValid(true);
      setCode(codeEntered);
    } else {
      setCodeValid(false);
    }
  }

  function onUsernameChange(event) {
    let usernameEntered = event.target.value;
    let usernameRegex =
      /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/;

    if (usernameRegex.test(usernameEntered)) {
      setUsername(usernameEntered);
      setUsernameValid(true);
    } else {
      setUsernameValid(false);
    }
  }

  function onPasswordChange(event) {
    let passwordEntered = event.target.value;
    let passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    if (passwordRegex.test(passwordEntered)) {
      setPassword(passwordEntered);
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }

  function onVerifyCodeClick(event) {
    verifyCode(phoneNumber, code)
      .then((response) => {
        if (response.success) {
          setStatus("user_verified");
          setExternalAccountId(response.data.user_id);
          setToken(response.data.token);
        } else {
          setCodeValid(false);
          // setStatus("errorred");
          // setErrorMessage("authentication failed");
          // redirect(false, { reason: "authentication_failed" }, 20000);
        }
      })
      .catch((err) => {
        setStatus("errorred");
        setErrorMessage(`failed: ${err.message}`);
      });
  }

  function onLoginClick() {
    login(username, password)
      .then((response) => {
        if (response.success) {
          setStatus("user_verified");
          setExternalAccountId(response.data.user_id);
          setToken(response.data.token);
        } else {
          setPasswordValid(false);
          // setStatus("errorred");
          // setErrorMessage("authentication failed");
          // redirect(false, { reason: "authentication_failed" }, 20000);
        }
      })
      .catch((err) => {
        setStatus("errorred");
        setErrorMessage(`failed: ${err.message}`);
      });
  }

  function onAllowClicked(event) {
    linkUser(clientId, scopes, token)
      .then((response) => {
        redirect(true, { external_account_id: externalAccountId, token });
      })
      .catch((err) => {
        setStatus("errorred");
        setErrorMessage(`failed: ${err.message}`);
      });
  }

  function onCancelClicked(event) {
    redirect(false, { reason: "user_denied_permission" }, 2000);
  }

  function redirect(success, params, delay = 0) {
    setTimeout(() => {
      window.location.href = `${redirectURI}?success=${success}&${new URLSearchParams(
        params
      ).toString()}`;
    }, delay);
  }

  function onGoogleSigninSuccess(response) {
    let idToken = response.credential;

    googleSigninCallback(idToken)
      .then((response) => {
        if (response.success) {
          setStatus("user_verified");
          setExternalAccountId(response.data.user_id);
          setToken(response.data.access_token);
        } else {
          setStatus("errorred");
          setErrorMessage(`failed: ${response.message}`);
        }
      })
      .catch((err) => {
        setStatus("errorred");
        setErrorMessage(`failed: ${err.message}`);
      });
  }

  function onGoogleSigninFailure(error) {
    setStatus("errorred");
    setErrorMessage(`failed: ${error.message}`);
  }

  function onAppleSigninSuccess(response) {
    let idToken = response.authorization.id_token;

    appleSigninCallback(idToken)
      .then((response) => {
        if (response.success) {
          setStatus("user_verified");
          setExternalAccountId(response.data.user_id);
          setToken(response.data.access_token);
        } else {
          setStatus("errorred");
          setErrorMessage(`failed: ${response.message}`);
        }
      })
      .catch((err) => {
        setStatus("errorred");
        setErrorMessage(`failed: ${err.message}`);
      });
  }

  function onAppleSigninFailure(error) {
    setStatus("errorred");
    setErrorMessage(`failed: ${error.message}`);
  }

  return (
    <div className="w-full h-full flex justify-center items-center ">
      <div className="w-72">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-full flex flex-col items-center">
            <img
              src="https://res.cloudinary.com/dwd3juhqv/image/upload/q_auto,f_auto/logo_transparent.png"
              className="w-16 mt-20"
              alt="reckit logo"
            />
            {status !== "user_verified" && (
              <div className="text-white font-bold text-center mt-8 text-lg">
                Log-in to your Reckit account
              </div>
            )}
          </div>
          {status === "initializing" && (
            <div className="w-full pl-16 pr-16 pt-8">
              <div className="flex justify-center items-center mt-8 h-full">
                <img
                  className="w-10 h-10"
                  src="https://res.cloudinary.com/dwd3juhqv/image/upload/v1673505882/loader.gif"
                  alt="loader animation"
                ></img>
              </div>
            </div>
          )}
          {status === "errorred" && (
            <div className="w-full pl-16 pr-16 pt-8">
              <div className="text-red-400">Error: {errorMessage}</div>
            </div>
          )}
          {status === "client_verified" && loginType === "code" && (
            <div className="w-full pl-6 pr-6 mt-8">
              <div className="w-full">
                <div className="text-white flex justify-center items-center border border-gray-400 rounded-xl text-base mb-3">
                  <button
                    className="bg-primary w-1/2 m-0.5 rounded-xl text-xs text-black p-1.5"
                    onClick={onCodeLoginSelected}
                  >
                    Phone
                  </button>
                  <button
                    className="w-1/2 m-0.5 rounded-xl text-xs text-gray-400 p-1.5"
                    onClick={onPasswordLoginSelected}
                  >
                    Username
                  </button>
                </div>

                <PhoneInput
                  country={"us"}
                  onChange={onPhoneNumberChange}
                  enableSearch="true"
                  inputProps={{
                    className:
                      "bg-background border border-gray-400 rounded-xl w-full p-3 text-sm text-white pl-12",
                  }}
                  render={({ inputProps, handleClick }) => {
                    return (
                      <div>
                        <input {...inputProps} />
                        <button className="" onClick={handleClick}>
                          Click me
                        </button>
                      </div>
                    );
                  }}
                />

                {phoneNumberValid !== null && !phoneNumberValid && (
                  <div className="text-xs text-red-500 text-center mb-1">
                    invalid phone number
                  </div>
                )}
                <div
                  className={`w-full ${
                    phoneNumberValid !== null && !phoneNumberValid
                      ? "mt-2"
                      : "mt-3"
                  }`}
                >
                  <button
                    className="rounded-xl border border-gray-400 bg-background text-white font-regular w-full p-3 text-sm"
                    onClick={onSendCodeClick}
                  >
                    Send one-time password
                  </button>
                </div>
              </div>
            </div>
          )}
          {status === "client_verified" && loginType === "password" && (
            <div className="w-full pl-6 pr-6 mt-8">
              <div className="w-full">
                <div className="text-white flex justify-center items-center border border-gray-400 rounded-xl text-base mb-3">
                  <button
                    className="w-1/2 m-0.5 rounded-xl text-xs text-gray-400 p-1.5"
                    onClick={onCodeLoginSelected}
                  >
                    Phone
                  </button>
                  <button
                    className="bg-primary w-1/2 m-0.5 rounded-xl text-xs text-black p-1.5"
                    onClick={onPasswordLoginSelected}
                  >
                    Username
                  </button>
                </div>
                <input
                  type="text"
                  placeholder="Username"
                  className="text-sm bg-background rounded-xl border border-gray-400 w-full text-white p-3"
                  onChange={onUsernameChange}
                ></input>
                {usernameValid !== null && !usernameValid && (
                  <div className="text-xs text-red-500 text-center mt-1 mb-1">
                    invalid username
                  </div>
                )}
                <input
                  type="password"
                  placeholder="Password"
                  className={`text-sm bg-background rounded-xl border border-gray-400 w-full text-white p-3 ${
                    usernameValid !== null && !usernameValid ? "mt-1" : "mt-3"
                  }`}
                  onChange={onPasswordChange}
                ></input>
                {passwordValid !== null && !passwordValid && (
                  <div className="text-xs text-red-500 text-center mb-1">
                    invalid password
                  </div>
                )}
                <div
                  className={`w-full ${
                    passwordValid !== null && !passwordValid ? "mt-1" : "mt-3"
                  }`}
                >
                  <button
                    className="rounded-xl bg-primary text-black font-bold w-full p-3 text-sm"
                    onClick={onLoginClick}
                    disabled={!usernameValid && !passwordValid}
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          )}
          {status === "client_verified" && status !== "user_verified" && (
            <div className="w-full pl-6 pr-6 mt-1 flex-col justify-center items-center content-center">
              <div className="font-light text-center p-2 text-gray-400 text-xs">
                or
              </div>
              <div>
                {/* <div
                  id="g_id_onload"
                  data-client_id="217111575970-nik931ta595hi7edm8mo9lsllea177uo.apps.googleusercontent.com"
                  data-context="signin"
                  data-callback="onGoogleSigninSuccess"
                  data-nonce=""
                  data-itp_support="true"
                ></div> */}

                {/* <div
                  id="g_id_onload"
                  data-client_id="217111575970-nik931ta595hi7edm8mo9lsllea177uo.apps.googleusercontent.com"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-callback="onGoogleSigninSuccess"
                  data-nonce=""
                  data-auto_prompt="false"
                ></div>

                <div
                  class="g_id_signin"
                  data-type="standard"
                  data-shape="pill"
                  data-theme="outline"
                  data-text="signin_with"
                  data-size="large"
                  data-logo_alignment="center"
                  data-width="100%"
                ></div> */}
              </div>
              <GoogleOAuthProvider clientId="217111575970-nik931ta595hi7edm8mo9lsllea177uo.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={onGoogleSigninSuccess}
                  onError={onGoogleSigninFailure}
                  shape="pill"
                  size="large"
                  width="240"
                  logo_alignment="center"
                  style={{ width: "100%" }}
                />
              </GoogleOAuthProvider>
              {/* <div>
                  <button
                    className="text-sm w-full bg-gray-100 rounded-full p-2 text-gray-900 font-medium"
                    onClick={() => googleLogin()}
                  >
                    Sign In with Google
                  </button>
                </div> */}
              <div className="mt-4">
                <AppleSignin
                  authOptions={{
                    clientId: "com.goreckit.prod-auth",
                    scope: "email name",
                    redirectURI: "https://prod-auth.goreckit.com",
                    state: "state",
                    nonce: "nonce",
                    usePopup: true,
                  }}
                  uiType="dark"
                  onSuccess={onAppleSigninSuccess}
                  onError={onAppleSigninFailure}
                  skipScript={false}
                  render={(props) => (
                    <button
                      className="text-sm w-full bg-gray-100 rounded-full p-2 text-gray-900 font-medium h-10"
                      {...props}
                    >
                      <span className="text-2xl relative -top-0.5 -left-2">
                        
                      </span>{" "}
                      <span className="relative -top-1.5">
                        Sign In with Apple
                      </span>
                    </button>
                  )}
                />
              </div>
            </div>
          )}
          {status === "sent_otp" && (
            <div className="w-full pl-6 pr-6 mt-8">
              <div className="w-full">
                <div className="text-center text-gray-400 pb-4">
                  Enter the verification code we sent to {phoneNumber}
                </div>
              </div>

              <input
                type="text"
                placeholder="XXXXXX"
                className="text-sm bg-background rounded-xl border border-gray-400 w-full text-white p-3"
                onChange={onCodeChange}
              ></input>
              {codeValid !== null && !codeValid && (
                <div className="text-xs text-red-500 text-center mb-1">
                  invalid code
                </div>
              )}
              <div className="pt-4 w-full">
                <button
                  className="rounded-xl bg-primary text-black font-bold w-full p-3 text-sm"
                  onClick={onVerifyCodeClick}
                >
                  Verify Code
                </button>
              </div>
            </div>
          )}

          {status === "user_verified" && (
            <div className="w-full pl-6 pr-6 mt-8">
              <div className="w-full">
                <div className="text-gray-400 pb-4 text-xl">
                  <span className="font-bold text-white text-left">
                    WeCare{" "}
                  </span>
                  wants to access your Reckit account <br />
                  <br />
                </div>
                <div className="text-regular text-gray-400 mb-10">
                  This will allow{" "}
                  <span className="text-white font-bold">WeCare</span> to:
                  <div>
                    <span className="text-3xl">·</span> See your recommendations
                    on Reckit
                  </div>
                </div>
                <button
                  className="rounded-xl bg-primary text-black font-bold w-full p-3 text-sm"
                  onClick={onAllowClicked}
                >
                  Allow
                </button>

                <button
                  className="rounded-xl text-gray-400 font-bold w-full p-3 text-sm mt-4"
                  onClick={onCancelClicked}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <div className="w-full flex flex-col justify-center items-center pt-40">
            <div className="text-gray-400 text-xs">© 2023 by Reckit, Inc.</div>
            <div className="mt-2">
              <span className="text-xs text-center privacy-eula">
                <a href="https://goreckit.com/privacy">Privacy </a> |{" "}
                <a href="https://www.termsfeed.com/live/acb15dae-9905-4fe5-89f2-8b292bc912c0">
                  EULA
                </a>
              </span>
            </div>
            <div className="w-full md:w-72 flex justify-center items-center p-4">
              <a
                className="apple-store-logo h-10 w-1/2 m-2 ml-4"
                href="https://apps.apple.com/us/app/reckit-share-find-save-recs/id1622200433"
              ></a>
              <a
                className="google-play-logo h-10 w-1/2 m-2 mr-4"
                href="https://play.google.com/store/apps/details?id=cc.RecKit.RecKitApp"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
